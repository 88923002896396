import { Dialog } from "@mui/material";

type DialogProps = {
	open: boolean;
	handleClose: () => void;
};

const DialogComponent = (props: DialogProps) => {
	const { handleClose, open } = props;
	return (
		<>
			<Dialog onClose={handleClose} open={open} className="dialog">
				<div className="camera-permission">
					<img src="/images/alert.png" alt="alert" />
					<h3>No Camera Access</h3>
					<p>To test with eMed, your browser needs permission to access the camera.</p>
					<br />
					<p className="dialog-legend-desktop">
						{" "}
						Can’t enable your camera? <br className="dialog-break-desktop" />{" "}
						<a href="https://www.emed.com/contact">We’re here to help.</a>
					</p>
				</div>
			</Dialog>
		</>
	);
};

export default DialogComponent;
