export type ScanResult = {
	imgUrl: string;
	testName: string;
	url: string;
	supported: boolean;
};

export type Test = {
	imgSrc: string;
	id: string;
	title: string;
	supported: boolean;
	subtitle: String;
	name: string;
	url?: string;
};

export type ValidatorState = {
	// pulse: boolean;
	snapshots: string[];
	recognizedTest: string;
	error: string;
	scannedTest: Test | null;
	showModal: boolean;
	responseTime: number;
};

export type HandleScreenProps = {
	handleScreen: (arg: string) => void;
};

export type BoxValidatorProps = {
	handleLogError: (arg: string) => void;
	handleLogInfo: (arg: string) => void;
	sessionID?: string;
	apiKey?: string;
	imagingApiURL?: string;
};

export type CurrentScreenState = {
	currentScreen: string;
};

export type Params = {
	sessionId: string;
};

export type ValidatorComponentProps = {
	sessionID?: string;
	apiKey?: string;
	imagingApiURL?: string;
	params?: Params;
};

export type CovidTestsType = {
	imgSrc: string;
	title: string;
	subtitle: string;
	id: string;
	url: string;
	supported: boolean;
	name: string;
};

export type TestType = {
	test: CovidTestsType;
	selectedTest?: CovidTestsType;
	selectedTestHandler: (arg: CovidTestsType) => void;
};

export enum DetectionType {
	Boxes = "box",
	Faces = "faces",
	QrCode = "qrcode",
	Text = "text"
}

export type BoxLabels = {
	Name: string;
	Confidence: number;
};

export type TestBox = {
	CustomLabels: BoxLabels[];
};

export type TextDetection = {
	DetectedText: string;
	Confidence: number;
	Type?: string;
};

export type TextObj = {
	TextDetections: TextDetection[];
};

export type CoreSessionWith = {
	nextProcedureURI: string;
	sessionID: string;
};

export type CoreSession = {
	this: string;
	with: CoreSessionWith;
};

export type ScannedBox = {
	box: TestBox;
	text: TextObj;
	coreSession: CoreSession;
};
