import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BoxValidatorProps, DetectionType } from "../constants/types";
import { covidTestBoxes, constants } from "../constants/entities";
import CheckMark from "./CheckMark";
import { textConstants } from "../constants/validator-text-content";
import DialogComponent from "./Dialog";

const BoxValidator = (props: BoxValidatorProps) => {
	const { handleLogError } = props;
	const navigate = useNavigate();
	const { sessionId } = useParams();
	const showCheckMarkRef = useRef(false);
	const [showCheckMark, setShowCheckMark] = useState(false);
	const boxDetectTimeoutCounter = useRef<number>(1);
	const [readyForScanning, setReadyForScanning] = useState(false);
	const [deniedPermission, setDeniedPermission] = useState(false);
	const [permissionAccepted, setPermissionAccepted] = useState(false);
	const canvasRef = useRef<HTMLCanvasElement>(document.getElementById("canvas") as HTMLCanvasElement);

	useEffect(() => {
		const constraints = {
			video: {
				aspectRatio: 1,
				facingMode: {
					ideal: "environment"
				}
			}
		};

		navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				const video = document.querySelector("video");
				if (video) {
					video.srcObject = stream;
					video.setAttribute("playsinline", "true");
					video.play();
					setPermissionAccepted(true);
				}
			})
			.catch((error) => {
				handleLogError(`Navigator getUserMedia Catch: ${error}`);
				//This state is in charge of displying the modal
				setDeniedPermission(true);
			});
	}, [navigate, sessionId, handleLogError]);

	useEffect(() => {
		const timer = setTimeout(() => {
			//Scanning state will also depend on the acceptance of using camera
			if (!readyForScanning && permissionAccepted) {
				setReadyForScanning(true);
			}
		}, parseInt(process.env.REACT_APP_BOX_VALIDATOR_STARTUP_TIMER_MS || "0"));
		return () => {
			clearTimeout(timer);
		};
	}, [readyForScanning, permissionAccepted]);

	const takePicture = useCallback(() => {
		const canvas = canvasRef.current;
		const video = document.querySelector("video");

		if (!canvas) {
			return;
		}

		const canvasContextType = "2d";
		const context = canvas.getContext(canvasContextType);

		if (!context || !video) {
			return;
		}
		canvas.width = video.offsetWidth;
		canvas.height = video.offsetHeight;

		context.drawImage(video, 0, 0, canvas.width, canvas.height);

		sendImages(canvas.toDataURL("image/jpeg"));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sendImages = useCallback(async (picture: string) => {
		if (!props.sessionID) {
			return;
		}
		if (showCheckMarkRef.current) {
			return;
		}

		const url = process.env.REACT_APP_REKOGNITION_ENDPOINT || "";
		if (url !== "") {
			const isLastAttempt =
				boxDetectTimeoutCounter.current >=
				parseInt(process.env.REACT_APP_CORE_SESSION_TRY_DETECT_COUNT || "10");
			const options = {
				method: "POST",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: new URLSearchParams({
					sessionId: props.sessionID.split(":")[0],
					detectionTypes: JSON.stringify([DetectionType.Boxes, DetectionType.QrCode, DetectionType.Text]),
					image: JSON.stringify(picture),
					detectionThreshold: JSON.stringify(constants.detectionThreshold),
					supportedOCR: JSON.stringify(covidTestBoxes),
					lastAttempt: JSON.stringify(isLastAttempt)
				})
			};
			try {
				const res = await fetch(url, options);
				const data = await res.json();
				if (data && data.with) {
					if (
						data.with.coreSession &&
						data.with.coreSession.with &&
						data.with.coreSession.with.nextProcedureURI
					) {
						setShowCheckMark(true);
						showCheckMarkRef.current = true; // using ref due to useCallback scope issues
						setTimeout(
							(window.location.href = data.with.coreSession.with.nextProcedureURI),
							parseInt(process.env.REACT_APP_CORE_SESSION_REDIRECT_TIMER_MS || "0")
						);
					}
				}
				if (!isLastAttempt && !showCheckMarkRef.current) {
					takePicture();
				}
			} catch (error) {
				handleLogError(`Take Picture Catch: ${error}`);
				if (!isLastAttempt && !showCheckMarkRef.current) {
					setTimeout(() => {
						takePicture();
					}, 1000);
				}
			}
			boxDetectTimeoutCounter.current++;
		} else {
			handleLogError(`Error: REACT_APP_REKOGNITION_ENDPOINT is set to ""`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		//This help us to avoid hiting the endpoint while the browser is asking for permission (not rejected or accepted yet)
		if (permissionAccepted) {
			setTimeout(() => takePicture(), parseInt(process.env.REACT_APP_BOX_VALIDATOR_STARTUP_TIMER_MS || "0"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [permissionAccepted]);

	const handleClose = () => {
		setDeniedPermission(false);
	};

	return (
		<>
			{!permissionAccepted && !deniedPermission && <div className="prompt-desktop-overlay" />}
			{deniedPermission ? (
				<DialogComponent handleClose={handleClose} open={deniedPermission} />
			) : (
				<div className="validator-app-container">
					<div className={"validator-app"}>
						<div className="close-btn" onClick={() => navigate(`/${sessionId}`)}>
							<img src="/images/close-svg.svg" alt="close button" />
						</div>
						<div className="text-legend">
							<h3>
								{readyForScanning && !showCheckMark
									? textConstants.titleStepOne
									: !readyForScanning && !showCheckMark
									? textConstants.titleStepTwo
									: ""}
							</h3>
							<p>
								{readyForScanning && !showCheckMark
									? textConstants.subtitleStepOne
									: !readyForScanning && !showCheckMark
									? textConstants.subtitleStepTwo
									: ""}
							</p>
						</div>
						<canvas id="canvas" ref={canvasRef} />
						<video id="video" muted autoPlay playsInline />
						<div
							className={readyForScanning && !showCheckMark ? "video-overlay scan-bar" : "video-overlay"}
						>
							<div className={showCheckMark ? "successful-scanning" : "corners"}>
								<div />
								{showCheckMark && <CheckMark />}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default BoxValidator;
