import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ValidatorComponent from "./components/ValidatorComponent";
import TestList from "./pages/covid-tests-page";
const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/:sessionId"
					element={
						<React.StrictMode>
							<ValidatorComponent />
						</React.StrictMode>
					}
				/>
				<Route
					path="/tests-list/:sessionId"
					element={
						<React.StrictMode>
							<TestList />
						</React.StrictMode>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

export default App;
