const Header = () => {
	return (
		<div className="emed-header">
			<svg width="75" height="100%" viewBox="0 0 119 37" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M15.0005 33.1232C9.53887 33.1232 7.2264 29.1844 7.2264 22.0046V21.4929H22.4551C22.729 15.228 19.3668 10.3278 13.7834 9.89355C14.7267 10.762 15.3504 11.8785 15.7307 13.0725C15.7307 13.119 15.7764 13.15 15.7764 13.1966C16.5979 15.8173 16.324 19.4304 16.248 19.9887C16.2023 20.2213 16.2023 20.1794 16.1719 20.412H7.27201C7.39372 17.7603 7.97188 14.7008 8.99118 12.9174C9.691 11.6459 10.3147 10.6534 10.9385 10.0176C4.38147 10.886 0 16.391 0 23.9275C0 31.3089 3.94029 36.814 11.5622 36.814C17.2673 36.814 20.386 33.2473 22.5007 29.5876L22.1812 29.386C20.5838 31.6966 17.9671 33.1232 15.0005 33.1232Z"
					fill="white"
				/>
				<path
					d="M58.9979 30.3163L57.6744 8.71485C57.4766 4.90009 57.2484 2.44997 58.2221 2.32591V1.96924H50.7675L42.0958 25.0749L32.7699 1.96924H24.8741V2.27938C25.8477 2.35692 25.6196 4.21779 25.4218 7.95501L24.3721 26.2534C24.0526 32.2857 24.0221 36.5347 22.3486 36.6897V36.9999H26.3346V36.6897C24.7372 36.6432 25.504 32.3633 25.854 26.2534L27.0254 6.32676L38.6423 36.9689H39.3224L50.5347 6.32676L51.6498 30.2698C51.8476 34.0845 52.0454 36.6587 50.95 36.6587V36.9689H60.9453V36.6587C59.6673 36.6742 59.2413 34.1311 58.9979 30.3163Z"
					fill="white"
				/>
				<path
					d="M75.9762 33.1232C70.5146 33.1232 68.2021 29.1844 68.2021 22.0046V21.4929H83.4308C83.7047 15.228 80.3425 10.2967 74.6831 9.89355C77.7258 12.6383 77.3455 18.3139 77.3455 18.3139C77.3455 19.0273 77.2694 19.4971 77.1477 20.3655H68.2478C68.3695 17.9464 68.8411 15.0574 69.7387 13.3051C70.4385 11.8009 71.1384 10.6844 71.8078 10.0176C65.2812 10.9635 60.991 16.4221 60.991 23.9275C60.991 31.3089 64.9313 36.814 72.5532 36.814C78.2583 36.814 81.377 33.2473 83.4917 29.5876L83.1723 29.386C81.5596 31.6966 78.9885 33.1232 75.9762 33.1232Z"
					fill="white"
				/>
				<path
					d="M109.75 29.4015V0H109.552L102.022 1.86086V2.171C102.524 2.85331 102.645 2.91534 102.645 6.20286V10.6844C101.428 10.2037 100.074 9.89357 98.5378 9.89357C90.7637 9.89357 85.3782 15.4606 85.3782 24.5788C85.3782 32.6736 89.6379 36.7985 95.2212 36.7985C98.4617 36.7985 101.002 34.8911 102.722 32.472L103.619 36.7985H103.817L110.724 35.0461V34.736C109.826 34.3173 109.75 32.6891 109.75 29.4015ZM102.645 31.4531C101.474 32.6006 100.074 33.3605 98.5378 33.3605C94.3541 33.3605 92.9088 29.4016 92.9088 22.4544C92.9088 16.2205 94.3084 10.9434 98.34 10.9434C101.383 10.9434 102.676 13.9099 102.676 18.5931V31.7431L102.645 31.4531Z"
					fill="white"
				/>
				<path
					d="M114.786 31.6348H112.23L112.139 32.6582H112.184C112.489 31.9759 112.702 31.7278 113.051 31.7278H113.067V34.3485C113.067 34.6897 113.051 34.9223 112.732 34.9223V34.9688H114.253V34.9223C113.949 34.9223 113.919 34.6897 113.919 34.3485V31.7278H113.949C114.299 31.7278 114.497 31.9759 114.816 32.6582H114.862L114.786 31.6348ZM118.635 34.364L118.513 32.2551C118.498 31.9139 118.528 31.7123 118.848 31.6813V31.6348H117.676L117.037 33.6352L116.322 31.6348H115.06V31.6813C115.273 31.7123 115.44 31.8674 115.425 32.193L115.333 33.8833C115.303 34.5036 115.227 34.8913 114.938 34.9223V34.9688H115.866V34.9223C115.562 34.9068 115.41 34.5036 115.455 33.8833L115.546 32.0379L116.642 34.9688H116.718L117.661 32.0379L117.783 34.333C117.798 34.6742 117.783 34.9068 117.479 34.9068V34.9533H119V34.9068C118.665 34.9223 118.65 34.6897 118.635 34.364Z"
					fill="white"
				/>
			</svg>
		</div>
	);
};

export default Header;
