
const covidTestBoxes = [
	{
		textToMatch: ["binaxnow", "abbot"],
		name: "binaxnow"
	},
	/*{
		textToMatch: ["lucira", "check it"],
		name: "lucira-checkit-covid19-athometest"
	},
	{
		textToMatch: ["roche"],
		name: "sdbiosensor-covid19-athometest"
	}*/
];

//Mocked array for tests lists
const testArray = [
	{
		imgSrc: "/images/binax.jpeg",
		title: "BinaxNOW",
		subtitle: "AntiGEN Self Test",
		id: "2",
		url: process.env.REACT_APP_BINAX_REDIRECT_URL || "",
		supported: true,
		name: "binaxnow"
	},
	{
		imgSrc: "/images/binaxNow.png",
		title: "BinaxNOW OTC",
		subtitle: "Over-the-Counter",
		id: "3",
		url: process.env.REACT_APP_BINAX_OTC_REDIRECT_URL || "",
		supported: false,
		name: ""
	},
	/*{
		imgSrc: "/images/lucira.jpeg",
		title: "Lucira",
		subtitle: "COVID-19",
		id: "1",
		url: process.env.REACT_APP_LUCIRA_REDIRECT_URL || "",
		supported: true,
		name: "lucira-checkit-covid19-athometest"
	},
	{
		imgSrc: "/images/roche.jpeg",
		title: "Roche",
		subtitle: "Self Test Nasal",
		id: "4",
		url: process.env.REACT_APP_ROCHE_REDIRECT_URL || "",
		supported: true,
		name: "sdbiosensor-covid19-athometest"
	}*/
];

const constants = {
	detectionThreshold: 80,
	redirectionBaseUrl:""
}

export { covidTestBoxes, testArray, constants };
