import { testArray } from "../constants/entities";
import Test from "../components/covid-test";
import { CovidTestsType } from "../constants/types";
import Header from "../components/header";

const TestList = () => {
	const selectedTestHandler = (test: CovidTestsType) => {
		const url = test.supported ? test.url : "https://www.emed.com/shop";
		window.location.href = url;
	};

	return (
		<div className="test-list-page">
            <Header></Header>
			<div className="tests-container">
				<h1>Please Select The Test Kit You Will Be Using</h1>
				<div className="tests-list">
					{testArray &&
						testArray.length &&
						testArray.map((test, idx) => {
							return <Test test={test} key={`Test-${idx}`} selectedTestHandler={selectedTestHandler} />;
						})}
				</div>
				<div>
					<a
						href="https://www.emed.com/shop"
						rel="noreferrer"
						target="_blank"
						className="btn-navigation choose"
					>
						MY TEST ISN&apos;T LISTED
					</a>
				</div>
			</div>
		</div>
	);
};

export default TestList;
