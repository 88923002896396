import { TestType } from "../constants/types";

const CovidTest = (props: TestType) => {
	const { test, selectedTest, selectedTestHandler } = props;
	return (
		<div
			className={test.id === selectedTest?.id ? "test-item item-active" : "test-item"}
			onClick={() => selectedTestHandler(test)}
		>
			<img src={test.imgSrc} alt="" />
			<div>
				<h3>{test.title}</h3>
				<p>{test.subtitle}</p>
				{!test.supported && (
					<div className="warning-container">
						<div className="warning-icon" />
						<span className="warning-text">Not supported</span>
					</div>
				)}
			</div>
			{test.id === selectedTest?.id && <div className="check-icon" />}
		</div>
	);
};

export default CovidTest;
