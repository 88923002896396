import { useEffect } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { ValidatorComponentProps } from "../constants/types";
import { useParams } from "react-router-dom";

import BoxValidator from "./box-validator";

const ValidatorComponent = (props: ValidatorComponentProps) => {
	const { sessionId } = useParams();
	useEffect(() => {
		initDatadog();
	}, []);

	const initDatadog = (): void => {
		const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

		if (!clientToken) {
			console.error("Datadog Client Token is missing");
			return;
		}

		datadogLogs.init({
			clientToken: clientToken,
			site: "datadoghq.com",
			forwardErrorsToLogs: false,
			sampleRate: 100,
			env: process.env.REACT_APP_ENV_SHORT_STACK,
			service: process.env.REACT_APP_PROJECT_NAME,
			version: process.env.REACT_APP_PROJECT_VERSION
		});
	};

	const handleLogError = (message: string) => {
		datadogLogs.logger.error(message);
	};

	const handleLogInfo = (message: string) => {
		datadogLogs.logger.info(message);
	};
	return (
		<>
			<BoxValidator
				handleLogError={handleLogError}
				handleLogInfo={handleLogInfo}
				sessionID={sessionId}
				apiKey={props.apiKey}
				imagingApiURL={props.imagingApiURL}
			/>
		</>
	);
};

export default ValidatorComponent;
